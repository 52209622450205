@import url('https://fonts.googleapis.com/css2?family=NTR&display=swap');

:root {
    /* background color */
    --dark-navy: #051122;
    --light-navy: #0d1a30;
    --hover-navy: #233554;

    /* text color */
    --light-gray: #ccd6f6;
    --dark-gray: #1f1f1f;
    --gray: #8892b0;
    --primary-color: #02B7DD;

    /* media query screen size */
    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;

    --transition: all 400ms ease;
}

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

body {
    font-family: 'NTR', sans-serif;
    background: var(--dark-navy);
    color: var(--gray);
    line-height: 1.7;

}


.container {
    /* width: var(--container-width-lg); */
    padding-left: 12%;
    padding-right: 12%;

    /* padding-top: 10%; */
    margin: 0 auto;
    padding-bottom: 5rem;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: 500;
}

h1 {
    font-size: 5rem;
    color: var(--light-gray);
    display: inline-block;
}


h1>span {
    font-size: 5rem;
    color: var(--primary-color);
}



h3 {
    font-size: 1.4rem;
    line-height: 1.4;
    margin-bottom: 0.5rem;
    word-spacing: 2px;
}



h3>span {
    font-size: 1.3rem;
    color: var(--primary-color);
    text-align: center;
}


img {
    display: block;
    width: 100%;
    object-fit: cover;
}

/* MEDIA QUERIES Med*/
@media screen and (max-width: 1024px) {

    .container {
        width: var(--container-width-md);
        padding-left: 0%;
        padding-right: 0%;
        padding-top: 0%;
    }


}

/* MEDIA QUERIES Sm*/
@media screen and (max-width: 600px) {
    .container {
        width: var(--container-width-sm);
        padding-bottom: 2rem;
    }

}